import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Icon, IconButton, Input, withStyles } from '@material-ui/core'
import { useMutation } from 'react-apollo'
import { getTranslation, Translate } from 'src/translation'
import { ReduxState } from 'src/store/reducers'
import { isAssistedSearchFeatureEnabled } from 'src/utils/featureFlagChecks'
import history from 'src/history'
import { userCommandGQL, userCommandVariables } from 'src/gql/userCommand'
import Spinner from './Spinner'
import { ErrorDialog } from './ErrorDialog'
import { sleep } from 'src/utils/sleepFunction'
import { isLPUser } from 'src/utils/common'

interface SearchMenuProps {
  tubeColor: string
  classes: {
    search: string
    homepageSearchIcon: string
  }
  isAssistedSearchFeatureEnabled: boolean
  translate: Translate
  isInMainToolbar: boolean
  hidetoolbarsearch: boolean
  isLPUser: boolean
}

const SearchMenu = (props: SearchMenuProps) => {
  const {
    tubeColor,
    classes,
    translate,
    isInMainToolbar,
    isAssistedSearchFeatureEnabled,
    hidetoolbarsearch,
    isLPUser,
  } = props
  const [isSearching, setIsSearching] = useState(false)
  const [search, setSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const isHomePage = window.location.pathname === '/home'

  const [createAssistedSearchTask] = useMutation(userCommandGQL)

  const shouldHideToolbarSearch = hidetoolbarsearch && isHomePage && isInMainToolbar && isAssistedSearchFeatureEnabled

  const onSearchChange = e => {
    setSearch(e.target.value)
  }

  const onSearchPress = async e => {
    if (e.type === 'keypress' && e.key !== 'Enter') return
    if (search.trim() === '') return

    if (!isAssistedSearchFeatureEnabled) {
      return history.push(`/results?search_query=${search.split(' ').join('+')}`)
    }

    if (isAssistedSearchFeatureEnabled && !isSearching) {
      setIsSearching(true)
      try {
        const { data } = await createAssistedSearchTask(
          userCommandVariables({
            type: 'createAssistedSearchTask',
            payload: {
              taskTitle: search,
            },
          })
        )
        if (isLPUser) {
          await sleep(4000)
          history.push(`/tasks?selectedTaskChatId=${data.res.taskId}&isFirstResonseToSearch=true`)

          return
        }
        await sleep(200)

        history.push(`/support/chat?taskId=${data.res.taskId}&isFirstResonseToSearch=true`)
      } catch (error) {
        const message = error.networkError?.result?.message || error.message

        setErrorMessage(message)
        setIsSearching(false)
      }
    }
  }

  return (
    <>
      <div
        style={{
          display: shouldHideToolbarSearch ? 'none' : 'flex',
          height: isInMainToolbar ? 'unset' : '65px',
          borderRadius: isInMainToolbar ? 5 : 50,
        }}
        className={classes.search}
      >
        <Input
          id="search"
          placeholder={
            isAssistedSearchFeatureEnabled
              ? (translate('assisted-search-input-placeholder') as string)
              : (translate('search') as string)
          }
          className="pl-16 w-full"
          disableUnderline={true}
          value={search}
          onChange={onSearchChange}
          onKeyPress={onSearchPress}
        />

        <IconButton
          id="search-btn"
          className={!isInMainToolbar ? classes.homepageSearchIcon : ''}
          onClick={onSearchPress}
        >
          {!isSearching ? <Icon>search</Icon> : <Spinner tubeColor={tubeColor} variant="tube" />}
        </IconButton>
      </div>
      <ErrorDialog open={Boolean(errorMessage)} message={errorMessage} onClose={() => setErrorMessage(null)} />
    </>
  )
}
const mapStateToProps = (state: ReduxState) => ({
  tubeColor: state.auth.tube.color,
  translate: getTranslation(state.translation.lang),
  isAssistedSearchFeatureEnabled: isAssistedSearchFeatureEnabled(state),
  hidetoolbarsearch: state.toolbar.hidetoolbarsearch,
  isLPUser: isLPUser(state.auth.user.email),
})

const styles = () => ({
  search: {
    border: '1px solid #c4c4c4',
    backgroundColor: '#fff',
    margin: 10,
    width: '97%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  homepageSearchIcon: {
    height: '100px',
    width: '65px',
    backgroundColor: '#fafafa',
    borderRadius: 0,
    borderLeft: '1px solid #c4c4c4',
  },
})

export default connect(mapStateToProps)(withStyles(styles)(SearchMenu))
